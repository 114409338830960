import { createSlice } from '@reduxjs/toolkit'
//domain:'http://127.0.0.1:8000',
//domain:'https://nakawa-application-system.herokuapp.com',
//domain:'https://finance-system-nugsoft.nugsoftdemos.net',
export const userSlice = createSlice({
  name: 'user',
  initialState: {
      cart:[],
      // domain:'https://finance-system-nugsoft.nugsoftdemos.net',
      domain:'http://backend-finance-final.nugsoftdemos.net',
      // domain:'http://127.0.0.1:8000',
      token:'',
      userInfo:{},
      access:'',
      refresh:'',
      username:'',
      permissions:{}
  },
  reducers: {
    updateCart: (state, action) => {
        state.cart = action.payload
      },
    updateToken: (state, action) => {
      state.token = action.payload
    },
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    updateAccess: (state, action) => {
      state.access = action.payload
    },
    updateRefresh: (state, action) => {
      state.refresh = action.payload
    },
    updateUsername: (state, action) => {
      state.username = action.payload
    },
    updatePermissions: (state, action) => {
      state.permissions = action.payload
    },

  },
})

// Action creators are generated for each case reducer function
export const { updateCart, updateToken, updateUserInfo, updateRefresh, updateAccess, updateUsername, updatePermissions } = userSlice.actions

export default userSlice.reducer